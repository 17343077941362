@import '_variables';

img {
  max-width: 100%;
  height: auto;
}

video {
  max-width: 100%;
  height: auto;
}

.text-dark {
  color: @color-black;
}

.text-green {
  color: @color-green;
}

.text-blue {
  color: @color-blue;
}

.text-dark-light {
  color: @color-gray;
}

.text-dark-gray {
  color: @color-dark-gray;
}

.text-hight-gray {
  color: @color-hight-gray;
}

.text-light-blue {
  color: @color-light-blue;
}

.text-gray-blue-dark {
  color: @color-gray-blue-dark
}

.text-gray {
  color: @color-medium-gray;
}

.text-red {
  color: @color-red;
}

.text-white {
  color: @color-white;
}

.text-yellow {
  color: @color-yellow;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI/SegoeUI-Light.eot');
  src: local('Segoe UI Light'), local('SegoeUI-Light'),
    url('assets/fonts/SegoeUI/SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI/SegoeUI-Light.woff') format('woff'),
    url('assets/fonts/SegoeUI/SegoeUI-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI/SegoeUI-BoldItalic.eot');
  src: local('Segoe UI Bold Italic'), local('SegoeUI-BoldItalic'),
    url('assets/fonts/SegoeUI/SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI/SegoeUI-BoldItalic.woff') format('woff'),
    url('assets/fonts/SegoeUI/SegoeUI-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI/SegoeUI-SemiBold.eot');
  src: local('Segoe UI Semibold'), local('SegoeUI-SemiBold'),
    url('assets/fonts/SegoeUI/SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI/SegoeUI-SemiBold.woff') format('woff'),
    url('assets/fonts/SegoeUI/SegoeUI-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI/SegoeUI.eot');
  src: local('Segoe UI'), local('SegoeUI'),
    url('assets/fonts/SegoeUI/SegoeUI.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI/SegoeUI.woff') format('woff'),
    url('assets/fonts/SegoeUI/SegoeUI.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI/SegoeUI-Bold.eot');
  src: local('Segoe UI Bold'), local('SegoeUI-Bold'),
    url('assets/fonts/SegoeUI/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI/SegoeUI-Bold.woff') format('woff'),
    url('assets/fonts/SegoeUI/SegoeUI-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI/SegoeUI-Italic.eot');
  src: local('Segoe UI Italic'), local('SegoeUI-Italic'),
    url('assets/fonts/SegoeUI/SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI/SegoeUI-Italic.woff') format('woff'),
    url('assets/fonts/SegoeUI/SegoeUI-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Roboto-mono monospace */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Mono'),
       url('assets/fonts/Roboto_Mono/RobotoMono-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: bold;
  src: local('Roboto Mono Bold'),
       url('assets/fonts/Roboto_Mono/RobotoMono-Bold.ttf') format('truetype')
}
/* roboto-mono-italic - latin_cyrillic */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: normal;
  src: local('Roboto Mono Itilic'),
       url('assets/fonts/Roboto_Mono/RobotoMono-Italic.ttf') format('truetype')
}

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Segoe UI', "Helvetica Neue", sans-serif;
  font-style: normal;
  font-size: @font-medium;
}

a {
  cursor: pointer;
}

.aero-body {
  height: calc(100% - 60px);
}

button {
  outline: none;
}

.text-monospace {
  font-family: 'Roboto Mono', monospace !important;
  font-style: normal;
  font-weight: normal;
}

.btn {
  font-size: 16px;
  border-radius: 4px;
  height: 41px;
  font-weight: 600;
  min-width: 125px;
  display: inline-block;
  outline: none;
  box-shadow: none;
  padding: 0 7px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.dropdown-toggle::after {
  margin-left: 10px;
}

.btn-group > .dropdown-toggle::after {
  margin-left: 0;
}

.btn.active {
  background: @color-green;
  color: @color-white;
  border: none;

  i {
    color: @color-white;
  }
}

.btn {
  & * {
    display: inline-block;
    vertical-align: middle;
    line-height: 28px;
  }

  & i {
    font-size: 21px;
    margin-right: 5px;
  }

  &.btn-active {
    background-color: @color-green;
    color: @color-white;

    & i {
      color: @color-white;
    }
  }

  &:hover:not([disabled]) {
    border: 0.5px solid #DCDCDC;
  }
}

.btn-ico {
  & i {
    margin-right: 0;
    color: #5c5c5c;
  }
}

.btn-h-25 {
  font-size: 13px;
  min-width: 25px;
  height: 28px;
}

.btn-h-29 {
  font-size: 13px;
  min-width: 25px;
  height: 29px;
}

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active {
  i {
    color: #fff;
  }
}

.btn-h-30 {
  font-size: 13px;
  min-width: 50px;
  height: 30px;
}

.btn-h-31 {
  font-size: 13px;
  min-width: 50px;
  height: 31px;
}

.btn-xs {
  font-size: 13px;
  min-width: 46px;
  height: 35px;
}

// для кнопки-стрелки в выпадающих
.btn.btn-xxs {
  min-width: 35px;
  height: 35px;
}

.btn-sm {
  font-size: 14px;
  height: 35px;
  min-width: 104px;
}

.btn-lg {
  font-size: 19px;
  height: 47px;
  min-width: 145px;
}

.btn-light {
  color: @color-black;
  background-color: @color-white;
  border-color: @color-gray-blue;
}

.btn-light-gray {
  background-color: @color-light-gray;
  color: #5c5c5c;
  border: 1px solid #dee2e6;

  &[disabled] {
    background: @color-light-gray  !important;
    color: @color-gray  !important;
    opacity: 1;
    cursor: no-drop;

    i {
      color: @color-gray  !important;
    }

  }
}

.btn-white {
  background-color: @color-white;
  color: #5c5c5c;
  border: 1px solid #dee2e6;

  &[disabled] {
    background: @color-light-gray  !important;
    color: @color-gray  !important;
    opacity: 1;
    cursor: no-drop;

    i {
      color: @color-gray  !important;
    }

  }
}

.btn-dark-gray {
  background-color: @color-medium-gray;
  color: #5c5c5c;

  &[disabled] {
    background: @color-light-gray  !important;
    color: @color-gray  !important;
    opacity: 1;
    cursor: no-drop;

    i {
      color: @color-gray  !important;
    }

  }
}

.btn-light:hover {
  color: @color-white;
  background-color: @color-black;
}

.btn-dark {
  color: @color-white;
  background-color: @color-black;
  border-color: @color-black;

  &:hover {
    color: @color-white;
  }
}

.btn-medium-gray {
  background-color: @color-medium-gray;
  border: 1px solid #C4C4C4;
}

.btn-red {
  color: @color-white;
  background-color: @color-red;
  border-color: @color-red;

  &:hover {
    color: @color-white;

    i {
      color: @color-white;
    }
  }

  i {
    color: @color-white;
  }
}

.btn-blue {
  color: @color-white;
  background-color: @color-blue;
  border-color: @color-blue;

  &:hover {
    color: @color-white;

    i {
      color: @color-white;
    }
  }

  i {
    color: @color-white;
  }
}

.btn-yellow {
  color: @color-white;
  background-color: @color-yellow;
  border-color: @color-yellow;

  &:hover {
    color: @color-white;
  }
}

.btn-gray-blue-dark {
  background-color: @color-gray-blue-dark;
  color: @color-white;

  &:hover {
    color: @color-white;
  }
}

.input-group-text {
  background-color: @color-light-gray;
}

.bg-none {
  background-color: transparent!important;
}

.bg-white {
  background-color: @color-white!important;
}

.bg-blue {
  background-color: @color-blue!important;
}

.bg-light-blue {
  background-color: @color-light-blue!important;
}

.bg-light-gray {
  background-color: @color-light-gray!important;
}

.bg-medium-gray {
  background-color: @color-medium-gray!important;
}

.bg-gray {
  background-color: @color-gray!important;
}

.bg-dark-gray {
  background-color: @color-dark-gray!important;
}

.bg-hight-gray {
  background-color: @color-hight-gray!important;
}

.bg-gray-blue-dark {
  background-color: @color-gray-blue-dark!important;
}

.bg-red {
  background-color: @color-red!important;
}

.bg-yellow {
  background-color: @color-yellow!important;
}

.bg-light-yellow {
  background-color: @color-light-yellow!important;
}

.color-white {
  color: @color-white;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('assets/img/cmodule/arrow_down.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 14px;
  box-sizing: border-box;
}

input[disabled],
select[disabled],
textarea[disabled],
ng-input-formatted[disabled] {
  background: @color-light-gray  !important;
  box-sizing: border-box;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f1f1f1 !important;
}

.ng-select.ng-select-disabled > .ng-select-container input[disabled] {
  background: none !important;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 7px;
  border-radius: 3px;
  background: @color-medium-gray;
  outline: none;
  transition: opacity 0.2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: @color-blue;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: @color-blue;
  cursor: pointer;
}

input.form-control-md,
input[type='date'].form-control-md,
input[type='time'].form-control-md {
  height: 35px;
}

button.btn-light-gray[disabled] {
  background: @color-light-gray  !important;
  color: @color-gray  !important;
  opacity: 1;
  cursor: no-drop;

  i {
    color: @color-gray  !important;
  }

}

.btn-green {
  background-color: @color-green!important;
  color: @color-white!important;
}


table.table {
  border-collapse: separate;
  border-spacing: 0;

  thead {
    tr {
      th {
        background-color: @color-blue;
        color: @color-white;
        border-right: 1px solid hsla(0, 0%, 100%, 0.1);
        border-top: 1px solid hsla(0, 0%, 100%, 0.1);
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
        font-weight: bold;

        &.head-border-right-bold {
          border-right: 2px solid hsla(0, 0%, 100%, 0.1);
        }

        &.head-border-top {
          border-top: 1px solid hsla(0, 0%, 100%, 0.1);
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        &.head-border__right-top--dark-large {
          font-size: @font-medium;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        height: 50px;
        padding: 4px 8px;
        position: relative;
        vertical-align: middle;
        border-bottom: solid 1px @color-gray-blue;

        &:after {
          content: '';
          width: 1px;
          position: absolute;
          right: 0;
          top: 5%;
          height: 90%;
          float: right;
          background: @color-gray-blue;
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-bottom: solid 1px @color-gray-blue;
          border-left: solid 1px @color-gray-blue;
        }

        &:last-child {
          border-right-width: 1px;
          border-right-color: @color-gray-blue;
          border-right-style: solid;
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;

          &:after {
            content: none;
          }
        }

        &.tbody-border-right-bold {
          border-right: 2px solid @color-gray-blue;
        }
      }

      &:hover {
        td {
          background-color: @color-light-gray;
          cursor: pointer;
        }
      }
    }
  }
}


.table-primary,
.table-hover tbody tr.table-primary:hover td,
.table-primary>td,
.table-primary>th {
  background-color: @color-light-blue;
  color: @color-white;
}

.extra-small {
  font-size: @font-extra-small;
}

.modal-xxl {
  max-width: 1500px;
}

.modal-header {
  background-color: @color-blue;
  z-index: 10;
  border: none;
}

.modal-header {
  h5 {
    color: @color-white;
    font-size: @font-large;
    font-weight: bold;
  }
}

.modal-header, .modal-footer, .modal-content {
  border-radius: 0;
}

.modal-header, .modal-content {
  border: 0!important;
}

.modal-nav {
  .nav-tabs {
    border: none;

    .nav-link {
      border: 1px solid transparent;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      color: @color-gray-blue-dark;
      font-weight: 600;

      &.active {
        color: @color-white;
        background-color: @color-blue;
      }
    }
  }
}

button.close,
button.close span {
  color: @color-white;
  font-size: @font-large;
  opacity: 1;
}

.font-large {
  font-size: @font-large;
}

.font-medium {
  font-size: @font-medium;
}

.font-small {
  font-size: @font-small;
}

.font-extra-small {
  font-size: @font-extra-small;
}

.list-group-flush {
  .list-group-item {
    text-align: left;
    background-color: @color-white;
    font-size: @font-medium;
    color: @color-black;
    font-weight: 500;
    border-bottom: none;

    &.active {
      background-color: @color-medium-gray;
      font-weight: bold;
    }
  }

  &:hover {
    .list-group-item {
      &:hover {
        background-color: @color-light-gray;
      }
    }
  }
}

ul.pagination {
  align-items: center;
  justify-content: flex-end;
}

.page-item {
  &.active {
    border-color: @color-gray-blue-dark;

    & .page-link {
      font-weight: bold;
      background: none;
      color: @color-black;
      border-color: @color-gray-blue-dark;
    }
  }
}

label.blue-bold-label {
  color: @color-blue;
  font-weight: bold;
}

.aero-top-panel {
  background: @color-white;
  border-radius: 0 0 4px 4px;
  z-index: 1;
  position: relative;
}

input[type='date'],
input[type='time'] {
  -moz-appearance: textfield;
  height: 31px;
  font-size: @font-small;
}

input[type="file"] {
  width: 100%;
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input::-webkit-datetime-edit,
input::-webkit-datetime-edit-fields-wrapper,
input::-webkit-datetime-edit-text,
input::-webkit-datetime-edit-month-field,
input::-webkit-datetime-edit-day-field,
input::-webkit-datetime-edit-year-field,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.modal.show {
  .modal-dialog {
    overflow: initial;
  }
}

.ms-modal-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  & .alert-danger {
    position: relative;
    padding: 10px 50px 10px 10px;
    min-width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 4px;
    z-index: 10;
  }

  & .modal-content {
    width: auto;
    max-height: 100%;
  }

  & .modal-body {
    overflow-y: auto;
  }
}

.ms-modal-server-error {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  & .alert-danger {
    position: relative;
    padding: 10px 50px 10px 10px;
    min-width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 4px;
    z-index: 10;
  }

  & .modal-content {
    width: auto;
    max-height: 100%;
  }

  & .modal-body {
    overflow-y: auto;
  }
}

.ms-modal-close-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.required-asterisk {
  font-weight: bold;
  color: @color-red;
}

.form-control.ng-invalid.ng-touched {
  border-color: @color-red;
  // background-color: rgba(197, 27, 27, 0.1);
}

/* подсказка, код: <span class="prompt" placement="top" ngbTooltip="текст подсказки">?</span> */
span.prompt {
  font-size: 12px;
  background: @color-green;
  margin: auto 5px;
  display: inline-block;
  width: 15px;
  color: @color-white;
  text-align: center;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  cursor: pointer;
}

span.delete-button, span.add-button {
  font-size: @font-small;
  font-weight: bold;
  color: @color-white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
  cursor: pointer;
}

span.delete-button {
  background: @color-red;
}

span.add-button {
  background: @color-green;
}

.form-control {
  box-shadow: none !important;
  outline: none !important;
}

.custom-control-input {
  &:checked~.custom-control-label {
    &::before {
      border-color: @color-green;
      background-color: @color-green;
    }
  }
}

// Sidebar
.ng-sidebar {
  z-index: 30 !important;
}

.app-sidebar {
  background-color: #fff;
  padding: 10px;
  z-index: 3;
  min-width: 300px;

  &.ng-sidebar--opened.ng-sidebar--over {
    box-shadow: 0 0 1.5em rgba(85, 85, 85, 0.5);
    z-index: 100;
  }
}

.navbar-brand {
  padding: 0;
  cursor: pointer;

  &.material-icons {
    padding: 0;
  }
}

.mat-form-field-appearance-legacy {
  & .mat-form-field-underline {
    bottom: 0;
  }

  & .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-form-field-infix {
  border-top: none;
}

.card__header--important {
  color: @color-blue;
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 27px;

  & i {
    font-size: 24px;
    margin-right: 8px;
  }
}

.list-group-item.active {
  border-color: @color-medium-gray;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5);
}

.cssload-spin-box {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: 15px 15px rgb(79, 77, 73),
    -15px 15px rgb(223, 223, 223),
    -15px -15px rgb(79, 77, 73),
    15px -15px rgb(223, 223, 223);
  -o-box-shadow: 15px 15px rgb(79, 77, 73),
    -15px 15px rgb(223, 223, 223),
    -15px -15px rgb(79, 77, 73),
    15px -15px rgb(223, 223, 223);
  -ms-box-shadow: 15px 15px rgb(79, 77, 73),
    -15px 15px rgb(223, 223, 223),
    -15px -15px rgb(79, 77, 73),
    15px -15px rgb(223, 223, 223);
  -webkit-box-shadow: 15px 15px rgb(79, 77, 73),
    -15px 15px rgb(223, 223, 223),
    -15px -15px rgb(79, 77, 73),
    15px -15px rgb(223, 223, 223);
  -moz-box-shadow: 15px 15px rgb(79, 77, 73),
    -15px 15px rgb(223, 223, 223),
    -15px -15px rgb(79, 77, 73),
    15px -15px rgb(223, 223, 223);
  animation: cssload-spin ease infinite 4.6s;
  -o-animation: cssload-spin ease infinite 4.6s;
  -ms-animation: cssload-spin ease infinite 4.6s;
  -webkit-animation: cssload-spin ease infinite 4.6s;
  -moz-animation: cssload-spin ease infinite 4.6s;
}

@keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73);
  }

  50% {
    box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
  }
}

@-o-keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73);
  }

  50% {
    box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
  }
}

@-ms-keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73);
  }

  50% {
    box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
  }
}

@-webkit-keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73);
  }

  50% {
    box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
  }
}

@-moz-keyframes cssload-spin {

  0%,
  100% {
    box-shadow: 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223);
  }

  25% {
    box-shadow: -15px 15px rgb(223, 223, 223), -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73);
  }

  50% {
    box-shadow: -15px -15px rgb(79, 77, 73), 15px -15px rgb(223, 223, 223), 15px 15px rgb(79, 77, 73), -15px 15px rgb(223, 223, 223);
  }

  75% {
    box-shadow: 15px -15px #dfdfdf, 15px 15px #4f4d49, -15px 15px #dfdfdf, -15px -15px #4f4d49;
  }
}

.drop-down-long  {
  .ng-dropdown-panel {
      min-width: 350px !important;
      max-width: 350px !important;
  }
}

.ng-dropdown-panel  {
  .drop-down-long {
      min-width: 350px !important;
      max-width: 350px !important;
  }
}

// Уеличенный размер вывода списка аэропортов для карточки рейса
.airports-long  {
  .ng-dropdown-panel {
      min-width: fit-content !important;
      max-width: 350px !important;
  }
}

.ng-dropdown-panel {
  &.airports-long {
      min-width: 350px !important;
      max-width: 350px !important;
  }
}

// Уеличенный размер вывода списка для длинных строк, для тега установить appendTo="body"
.ng-dropdown-panel {
  &.select-long {
    width: auto !important;
    max-width: 350px;
    min-width: 100%;
  }
}

// Уеличенный размер вывода списка для длинных строк, для тега установить appendTo="body"

.ng-dropdown-panel {
  &.select-extra-long {
    width: auto !important;
    max-width: 550px;
    min-width: 100%;
  }
}

.ng-dropdown-panel {
  &.select-long {
      min-width: 350px !important;
      max-width: 350px !important;
  }
}

input[type="text"].custom-sm, input[type="number"].custom-sm {
  height: 29px;
}

// Набор стилий для ng-select
.ng-select {
  &.custom {
    border: none;
    min-height: 33px;
    height: 33px;

    .ng-select-container {
      // background-color: transparent;
      min-height: 33px;
      height: 33px;
    }
  }

  & ::ng-deep {
    & .ng-select-container {
      border: none;
      min-height: 33px;
      // height: 33px;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &.custom-sm {
    font-size: 0.75em;
    border: none;
    min-height: 0px;
    
    .ng-select-container {
      min-height: 27px;
      height: 27px;
    }

    .ng-select-container {
      // background-color: transparent;
      min-height: 31px;
    }
  }

  &.ng-select-disabled {
    background: @color-light-gray;
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          top: auto; 
        }
      }
    }
  }

}

.ng-select-nowrap-option {
  .ng-dropdown-panel-items .ng-option {
    white-space: break-spaces!important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 14px;
}

// поля ввода в таблицах
table {
  tr {
    td {
      input:not([type="checkbox"]) {
        border: 1px solid #ccc;
        outline: 0;
        min-width: 20px;
        width: 100%;
        border-radius: 3px;
        height: 29px;
      }
      .ng-select {
        &.custom-sm {
          font-size: 0.8em;
          min-height: 29px;
          height: 29px;
          .ng-select-container {
            min-height: 29px;
            height: 29px;
          }
        }
      }
    }
  }
}

// TODO перевестив less, который выше, с ходу не получилось

// Стили для ng-select в режими ошибки при валидаци
// Обводка
.ng-select.ng-invalid.ng-touched .ng-select-container{
  border-color: #A51F20;
}
// Стрелочка
.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow{
  border-color: #A51F20 transparent transparent
}
// Задний фон
.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder{
  color: red;
}

.form-control-sm {
  height: auto;
}

.nav-tabs .nav-link {
  color: #212529;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  font-weight: bold;
}

.wrapper-answer {
  text-align: center;
  padding-top: 3px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  font-size: 18px;
  font-weight: bold;
}

// button.dropdown-item {
//   font-size: @font-small;
//   display: flex;
// }

// button.dropdown-item:active {
//   background: @color-gray-blue-dark;
//   border: none;
//   outline: none;
// }

// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//   text-align: left;
// }

// .dropdown-item {
//   align-items: center;
//   justify-content: flex-start;
//   padding: 0 10px;
// }

// .dropdown-menu {
//   padding: 5px 0;
// }

.bg-info {
  background-color: #1f688e!important;
}

.toast-container {
    position: fixed;
    right: 10px;
    bottom: 0;
}

.ngx-toastr {
  padding: 15px;
  border-radius: 5px;
  min-width: 270px;
  opacity: 1;
  padding: 15px;
  position: relative;
  background: @color-light-gray;
  margin-bottom: 10px;
}

.toast-success {
  border: 2px solid @color-blue;
}

button.toast-close-button {
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.toast-message {
  font-size: 15px;
}

.toast-title {
  font-size: 16px;
  color: #1e688e;
  font-weight: bold;
}

.input-group > .ng-select:not(:first-child) .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
}

.modal-max-width {
  max-width: 80% !important;
}

.ngx-pagination {
  margin-bottom: 0!important;
  padding-left: 0!important;
}

.ngx-pagination .current {
  padding: 1px 7px;
  background: #1e698e;
}

.ngx-pagination a, .ngx-pagination button {
  padding: 1px 7px!important;
}

.ngx-pagination .pagination-previous, .ngx-pagination .pagination-next {
  display: none;
}

.message-text-wrapping {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}


.clear-filter {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  color: #4c5459;
  cursor: pointer;
}

.input-group > .position-relative {
  flex: 1 1 auto;
}

.referance-filter {
  flex-wrap: nowrap;
}

@media print {
  @page {
    margin: 10mm 10mm 10mm 20mm;
  }

  *, *:before, *:after {
		color: #000;
		box-shadow: none;
		text-shadow: none;
	}

  html, body {
    margin: 0;
    background-color: #fff;
    font-size: 14px;
    white-space: normal;
    overflow-wrap: anywhere;
    page-break-after: auto;
    height: auto;
    width: 100%;
  }
}

.line-height-1 {
  line-height: 1!important;
}


.filter-block-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 33px;
  width: 100%;
  max-width: 100%;
  position: relative;
  border-radius: 3px;
  border: 1px solid #ced4da;
}

.filter-element {
  background: #f1f1f1;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 0 0 5px;
  color: #313131;
  border-radius: 5px;
  overflow: hidden;
}

.filter-element i {
  background: #dcdcdc;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.accordion > .card {
  overflow: unset;
}


table.info-table {
  line-height: 100%;
  tr {
    th {
      background: @color-medium-gray;
      font-weight: normal;
    }

    td {
      background: @color-white;
      width: 100%;
    }

    td, th {
      border: 1px solid #C4C4C4;
      padding: 3px;
      vertical-align: top;
    }
  }
}

.columns-2 {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
}

.columns-3 {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
}

.filter-block-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 33px;
  width: calc(100% - 100px);
  position: relative;
  &.disabled {
    background: #f1f1f1;
    .input-group-text, i {
      color: @color-gray!important;
    }
  }
}

.filter-element {
  background: #ebf5ff;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 0 5px 0 0;
  color: @color-black;
  border-radius: 5px;
  overflow: hidden;

  i {
    background: #ebf5ff;
    border-right: 1px solid #b8dbff;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
  }

  span {
    margin-left: 5px;

    &.del {
      text-decoration: line-through;
    }
  }
}

.filter-block {
  position: absolute;
  top: 35px;
  left: 0;
  right: 78px;
  z-index: 10000;
  min-width: 400px;
  max-height: calc(100vh - 110px);
  // overflow: scroll;

  select {
    height: 27px;
    width: 100%;
  }

  input:not([type=checkbox]) {
    border-radius: 0;
    height: 27px;
    width: 100%;
  }
}

.table-sticky-header thead tr:last-child th {
  position: sticky;
  top: -1px;
  z-index: 2;
}

table.contenteditable {
  font-size: 14px;
  tr {
    td {
      border: 1px solid @color-light-gray;
      padding: 5px;
      outline: none;
    }
    th {
      padding: 5px;
      outline: none;
    }
  }
  tbody tr:nth-child(even) {
    background: @color-light-gray;
  }
  .d-hover {
    display: none;
  }
  i {
    font-size: 20px;
    display: none;
  }
  tr:hover i, tr .d-hover:checked, tr:hover .d-hover {
    display: block;
  }
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

.table-grid {
  .div-striped {
    .tooltip {
      opacity: 1;
      .tooltip-inner {
        background: @color-light-gray;
        border: 1px solid @color-gray-blue-dark;
        text-align: left;
        padding: 0;
        color: @color-black;
        overflow: hidden;
        line-height: 1.2;
      }
      .tooltip-header {
        background: @color-light-gray;
        border-bottom: 1px solid @color-gray-blue-dark;
        padding: 5px;
      }
      .tooltip-content {
        width: auto;
        max-width: none;
        background-color: @color-white!important;
        font-size: 13px;
        padding: 5px;
      }
    }
    .bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
      border-top-color: @color-gray-blue-dark;
    }
  }
}

.per-page {
  select {
    width: 45px;
  }
}

.alert-fixed-bottom {
  position: fixed;
  bottom: 15px;
  margin: 0;
  right: 20px;

  button.close,
  button.close span {
    color: inherit
  }
}

.custom-ngb-modal-window {
  z-index: 1055;
}

.custom-ngb-modal-backdrop {
  z-index: 1055;
}

table tbody .popover {
  z-index: 1;
}